import ColorChangableAxis from '@/components/ColorChangableAxis/ColorChangableAxis.vue';
import SkeletonLoadingImage from '@/components/SkeletonLoadingImage/SkeletonLoadingImage.vue';
import DecisionConst from '@/constants/DecisionConst';
import socketClient from '@/services/SOCKET';
import OpenAIService from '@/services/API/openai.service';
import ComodeAiConst from '@/constants/ComodeAiConst';
const PIE_PERCENT = 6.25;

export default {
	name: 'FormatMenu',
	components: { ColorChangableAxis, SkeletonLoadingImage },
	props: {
		formatMenuData: {
			type: Object,
		},
		disabled: {
			type: Boolean,
		},
		titleCaption: {
			type: String,
		},
		isShowScrollButton: {
			type: Boolean,
			defaut: false,
		},
		ideaText: String,
	},
	data() {
		return {
			processValue: 0,
			predictionStatus: DecisionConst.PREDICTION_STATUS.init,
			PREDICTION_STATUS: DecisionConst.PREDICTION_STATUS,
			progressBarInterval: null,
			generatedText: '',
			decisionContent: '',
			lable: '',
			KEYWORD_TITLES: DecisionConst.KEYWORD_TITLES,
			checked: false,
			generateTimeout: null,
			generatingText: false,
			/**
			 * Unit: degree
			 */
			pieAngle: 22.5,
			/**
			 * List of index of pie chart items corresponding to the formatMenuData.designTates array
			 */
			quaterCircleLefts: [15, 14, 13, 12, 7, 6, 5, 4],
			quaterCircleRights: [0, 1, 2, 3, 8, 9, 10, 11],
			status: false,
		};
	},

	computed: {
		pieChartStyle() {
			let gradientValue = '';
			let tempDesignTastes = [...this.formatMenuData?.designTastes];
			if (tempDesignTastes.length === 0) return;

			// Swap element due to displaying correct ordering on chart
			// Using destructuring assignment
			[
				tempDesignTastes[4],
				tempDesignTastes[5],
				tempDesignTastes[6],
				tempDesignTastes[7],
				tempDesignTastes[8],
				tempDesignTastes[9],
				tempDesignTastes[10],
				tempDesignTastes[11],
			] = [
				tempDesignTastes[8],
				tempDesignTastes[9],
				tempDesignTastes[10],
				tempDesignTastes[11],
				tempDesignTastes[4],
				tempDesignTastes[5],
				tempDesignTastes[6],
				tempDesignTastes[7],
			];

			tempDesignTastes.map((el, index) => {
				const color = el.value ? 'black' : 'transparent';
				gradientValue +=
					`${color} ${index * PIE_PERCENT}% ${(index + 1) * PIE_PERCENT}%` + ',';
			});

			// Remove last comma
			gradientValue = gradientValue.slice(0, -1);

			return `conic-gradient(${gradientValue})`;
		},
	},
	watch: {
		// 'formatMenuData.concept': function (newValue) {
		// 	this.onChangeText({ target: { value: newValue } }, 'concept');
		// },
		formatMenuData: {
			handler: function (newFormatMenuData) {
				this.detail();
			},
			deep: true,
		},
		ideaText: {
			handler: function (newVal) {
				this.detail();
			},
			deep: true,
		},
	},
	methods: {
		/**
		 *
		 * @param {String} type descibe type of format is selected
		 * @param {Integer} idx index of item selected
		 * @param {Boolean} isInput indicate that input is selected or not
		 * If true: no update value because of binding v-model
		 * if false: update value without binding v-model
		 */
		onChange(type, idx, isInput) {
			let currentValue = this.formatMenuData[`${type}`][idx].value;

			if (!isInput) {
				// Update curent value
				this.formatMenuData[`${type}`][idx].value = Number(!currentValue);
				currentValue = Number(!currentValue);
			}
			console.log('VALUE', currentValue);
			this.$emit('on-change', { type, idx, value: currentValue });
			//this.delayedGenerate();
		},

		onChangeText(event, type) {
			this.$emit('on-change-text', { type, value: event.target.value });
		},
		// delayedGenerate() {
		// 	if (this.generateTimeout) {
		// 		clearTimeout(this.generateTimeout);
		// 	}
		// 	this.generateTimeout = setTimeout(() => {
		// 		console.log('Call CHAT GPT');
		// 		//this.generateText();
		// 	}, 3000);
		// },
		/**
		 * Handle click event on pie chart
		 * @param {Integer} index index of current slide pie of chart
		 */
		onQuaterCircleClick(index) {
			if (this.disabled) return;

			let currentValue = this.formatMenuData.designTastes[index].value;

			// Update curent value
			this.formatMenuData.designTastes[index].value = Number(!currentValue);
			currentValue = Number(!currentValue);

			console.log('VALUE', currentValue);
			this.$emit('on-change', {
				type: 'designTastes',
				idx: index,
				value: currentValue,
			});
		},
		onClickingSelectedArea(id) {
			this.$emit('on-clicking-selected-area', id);
		},
		onInputDecision() {
			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'decision_content_edit_by_creator',
					content: this.decisionContent,
				},
				room: this.projectId,
			});
		},
		detail() {
			let labels = '';
			this.KEYWORD_TITLES.forEach((keywordTitle) => {
				const keywords = this.formatMenuData[keywordTitle.key];
				keywords.forEach((keyword) => {
					if (keyword.value === 1) {
						if (labels !== '') {
							labels += 'や';
						}
						labels += keyword.label;
					}
				});
			});

			this.decisionContent = this.ideaText + 'や' + labels;
			// this.$emit('update-decision-content', this.decisionContent);
		},
		resetValue() {
			this.predictionStatus = this.PREDICTION_STATUS.init;
			this.processValue = 0;
		},
		async generateText() {
			console.log(
				'%cGenerating',
				'color: green; font-weight: bold;',
				this.generatingText
			);
			if (this.generatingText) {
				return;
			}
			try {
				this.generatingText = true;
				this.$emit('update-status', (this.status = true));
				this.intervalProgressBar();
				const response = await OpenAIService.generateTextMatome(this.decisionContent);
				this.generatedText = response.data.choices[0].message.content.trim();
				const responseComodeAI = await OpenAIService.generateTextComodeAI(
					this.decisionContent
				);
				this.$emit(
					'update-decision-content',
					responseComodeAI.data.choices[0].message.content.trim()
				);
				this.formatMenuData.concept = this.generatedText;
				this.onChangeText({ target: { value: this.generatedText } }, 'concept');
				this.intervalProgressBar(100);
				this.generatingText = false;
				this.resetValue();
				this.$emit('update-status', (this.status = false));
			} catch (error) {
				if (!error.response) {
					this.errorModalContent = ComodeAiConst.NO_INTERNET;
				} else {
					let errorData = error.response.data.error;
					if (errorData.code == 'billing_hard_limit_reached') {
						this.errorModalContent = ComodeAiConst.BILLING_HARD_LIMIT_REACHED;
					} else if (errorData.message.includes('text that is not allowed')) {
						this.errorModalContent = ComodeAiConst.NSFW_TEXT;
					} else {
						this.errorModalContent = ComodeAiConst.GENERAL_ERROR;
					}
				}
				this.$bvModal.show(this.errorModalId);
				clearInterval(this.progressBarInterval);
				this.generatingText = false;
				this.resetValue();
			}
		},
		intervalProgressBar(processValue = 0) {
			this.processValue = processValue;
			this.predictionStatus = this.PREDICTION_STATUS.processing;
			this.progressBarInterval = setInterval(() => {
				if (this.processValue <= 25) {
					this.processValue += 3;
				} else if (this.processValue > 25 && this.processValue <= 50) {
					this.processValue += 1;
				} else if (this.processValue > 50 && this.processValue <= 70) {
					this.processValue += 2;
				} else if (this.processValue > 70) {
					clearInterval(this.progressBarInterval);
				}
			}, 500);
		},
	},
	mounted() {
		this.detail();
	},
};
