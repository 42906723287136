export default {
	props: {
		designTastes: {
			type: Array,
		},
	},
	data() {
		return {
			formalIndex: 0,
			casualIndex: 4,
			rigidIndex: 8,
			softIndex: 12,
		};
	},
};
