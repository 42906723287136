import ScreenHeader from '@/components/ScreenHeader/ScreenHeader.vue';
import ProjectDeadline from '@/components/ProjectDeadline/ProjectDeadline.vue';
import SaveButtonArea from '@/components/SaveButtonArea/SaveButtonArea.vue';
import BottomButton from '@/components/BottomButton/BottomButton.vue';
import ImageUpload from '@/components/ImageUploadWithProgressBar/ImageUploadWithProgressBar.vue';
import NotificationModal from '@/components/NotificationModal/NotificationModal.vue';
import ItemList from '@/components/ItemList/ItemList.vue';
import Badge from '@/components/Badge/StatusBadge.vue';
import TextArea from '@/components/TextArea/TextArea.vue';
import Modal from '@/components/Modal/Modal.vue';
import FormatMenu from '@/components/FormatMenu/FormatMenu.vue';
import SkeletonLoadingImage from '@/components/SkeletonLoadingImage/SkeletonLoadingImage.vue';
import AIPanel from '@/views/General/DecisionScreen/AIPanel/AIPanel.vue';

import DecisionService from '@/services/API/decision.service';
import DecisionDetailService from '@/services/API/decision_detail.service';
import TransferService from '@/services/API/transfer.service';
import CoCreateService from '@/services/API/co_create.service';
import socketClient from '@/services/SOCKET';

import { sendNotify, toLineMessageTemplate } from '@/utilities';
import ProjectService from '@/services/API/project.service';
import DecisionConst from '@/constants/DecisionConst';
import CommonConst from '@/constants/CommonConst';
import updateProjectStatusMixin from '@/mixin/updateProjectStatus';
import autoHeightTextArea from '@/mixin/autoHeightTextArea';
import scrollToTop from '@/mixin/scrollToTop';
import deviceChecking from '@/mixin/deviceChecking';
import downloadPdfMixin from '../../../mixin/downloadPdfMixin';

import moment from 'moment';
import { mapState } from 'vuex';

const DEFAULT_ITEM = {
	title: undefined,
	isChanged: undefined,
	isReaded: undefined,
	dateData: undefined,
	nonfilterDateData: undefined,
	itemId: undefined,
	commentContent: undefined,
	gridCols: undefined,
	gridStatus: undefined,
	showDateData: true,
	showTitle: true,
	statusIcon: 'QuestionStatus.png',
	isShowIcon: false,
	isColorChanged: false,
};

export default {
	name: 'DecisionScreen',
	mixins: [
		updateProjectStatusMixin,
		autoHeightTextArea,
		scrollToTop,
		deviceChecking,
		downloadPdfMixin,
	],
	props: {
		mobileLiveScreenMinimum: Boolean,
	},
	components: {
		ScreenHeader,
		ProjectDeadline,
		SaveButtonArea,
		BottomButton,
		ImageUpload,
		TextArea,
		NotificationModal,
		ItemList,
		Badge,
		Modal,
		FormatMenu,
		SkeletonLoadingImage,
		AIPanel,
	},
	data() {
		return {
			pdfFileIcon: CommonConst.PDF_FILE_ICON,

			middleContent: ' 様 ／ ',
			directLink: 'delivery',
			screenIcon: 'decision-icon',
			screenName: 'コンセプトデザイン',

			selectiveModalId: 'decision-selective-modal',
			selectiveContents: DecisionConst.SELECTIVE_CONTENTS.creator,
			confirmDeleteImgModalId: 'confirm-delete-image-modal',
			confirmDeleteModalContents: DecisionConst.CONFIRM_DELETE_CONTENTS,
			confirmDeleteQuestionModalId: 'confirm-delete-question-modal',
			confirmOpenFormatModalId: 'confirm-open-format-modal',
			confirmOpenFormatContents: DecisionConst.OPEN_MENU_NOTI_CONTENTS,
			imagePreviewModal: 'decision-preview-image-modal',
			pdfPreviewModal: 'decision-preview-pdf-modal',
			plainModalId: 'decision-plain-modal-id',
			plainContents: '保存しました。',

			currentDeletingImgId: undefined,
			currentDeletingQuestion: undefined,

			// Using for create decision details
			decisionId: undefined,
			decisionContent: '',
			decisionData: {},
			//Use to archive image items with image key
			questionList: [],
			selectedQuestion: {},
			deletedKeys: [],
			imgDataString: '',
			imgComment: '',
			imgFileKey: '',
			uploadFileParams: {
				role: 'decision',
			},
			buttonType: 'direct-screen-btn',
			buttonName: 'コンセプト仕上げに進む',
			badgeContent: '',
			badgeType: '',
			//Dummy Reason for check Status Badge title
			dummyReason: '',
			decisionStatus: '',
			imgCreatorDatas: [],
			imgClientDatas: [],
			displayedImageDatas: [],
			imageList: [],
			mailContent: '',
			selectedImageUrl: '',
			selectedImageComment: '',
			mailTitle: '',
			lable: '',
			isCreateQuestion: true,
			disableDecisionTextArea: false,
			isSaveButtonShowed: true,
			isQuotationSaved: false,
			showBottomButton: false,
			showBottomDecision: false,
			showStatusBadge: false,
			isShowUploadContainer: false,
			allowAdditionsQuestion: false,
			isShowQuestionDeleteButton: false,
			isFormatMenuDataChanged: false,
			isShowFormatMenu: false,
			isOpenFormatMenuDisabled: false,
			saveTimeout: null,
			bottomButtonName: '納品完了画面にすすむ',
			formatMenuData: {
				concept: '',
				designTastes: [],
				colorPalettes: [],
				fonts: [],
				logoFormats: [],
			},
			KEYWORD_TITLES: DecisionConst.KEYWORD_TITLES,
			subject: '',
			isSendCreator: false,

			pdfPreviewer: {
				url: '',
			},

			disableEditComment: false,

			// Show main content after fetch data from server successfully
			isShowContent: false,

			decisionBlock: undefined,
			lStageBlock: undefined,

			// Control ClientApp scroll
			isControlScroll: false,
			showFormatButton: false,
			isShowScrollSetupButton: false,

			// defaultFileName: '',
			// companyName: 'ハッピーカフェ',
		};
	},

	computed: {
		// Get projectId, userId from store
		...mapState([
			'projectId',
			'userId',
			'projectInfo',
			'clientInfo',
			'creatorInfo',
			'managementMasterInfo',
			'schedule',
			'appLogo',
			'appLogoPlain',
			'redDotIndexs',
		]),

		isShowScrollButton() {
			return (
				this.projectInfo['responsible_user'] == this.userId && this.isControlScroll
			);
		},
		disableUploadImgSaveButton() {
			return !this.imgFileKey;
		},
		// disableContentBtn() {
		// 	if (this.decisionData['confirm_flag'] === 1) return true;

		// 	if (this.decisionContent) {
		// 		console.log(this.decisionStatus);
		// 		if (
		// 			this.decisionStatus !== CommonConst.SCREEN_STATUS.WAITING_CONFIRM &&
		// 			this.decisionStatus !== CommonConst.SCREEN_STATUS.ACCEPT_CONFIRM
		// 		) {
		// 			return false;
		// 		} else {
		// 			return true;
		// 		}
		// 	} else {
		// 		return true;
		// 	}
		// },
		destinations() {
			return [this.clientInfo['email_address'], this.creatorInfo['email_address']];
		},
		mailTemplate() {
			return {
				destinations: this.isSendCreator
					? [this.creatorInfo['email_address']]
					: [this.clientInfo['email_address']],
				sender: this.managementMasterInfo['sender_email_address'],
				subject: this.subject,
				template: this.isSendCreator ? 'processmail_creator' : 'processmail_client',
				template_params: {
					projectName: this.projectInfo['project_name'],
					projectId: this.projectId,
					screenName: this.screenName,
					clientName: this.clientInfo['client_name'],
					clientId: this.clientInfo['id'],
					emailContent: this.mailContent,
					sendMailDate: moment().format('LLL'),
					appLogo: this.appLogo,
					appLogoFooter: this.appLogoPlain,
					processingScreenUrl: this.isSendCreator
						? `${this.projectInfo['process_creator_url']}decision`
						: `${this.projectInfo['process_client_url']}decision`,
					clientRegisterParams:
						`register_name=${this.clientInfo['client_name']}&` +
						`email_address=${this.clientInfo['email_address']}&phone_number=${this.clientInfo['phone_number']}` +
						`&account_type=client&client_id=${this.clientInfo['id']}&responsible_name=${this.clientInfo['responsible_name']}`,
				},
			};
		},
		lineMessage() {
			return toLineMessageTemplate({
				title: `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］`,
				message: this.mailContent,
				currentUrl: this.isSendCreator
					? `${this.projectInfo['process_creator_url']}decision`
					: `${this.projectInfo['process_client_url']}decision`,
			});
		},
		notifyContent() {
			return this.projectInfo['notify_method'] == 0
				? this.mailTemplate
				: this.projectInfo['notify_method'] == 1
				? this.lineMessage
				: {
						lineContent: this.lineMessage,
						mailContent: this.mailTemplate,
				  }; // eslint-disable-line no-mixed-spaces-and-tabs
		},
	},

	watch: {
		decisionContent: {
			handler: function () {
				if (this.decisionContent === '') {
					this.showBottomButton = false;
				}
			},
		},

		imageList(newVal) {
			if (newVal && newVal.length > 0) {
				let quantity = 0;

				newVal.map((imageData) => {
					if (
						imageData['user_id'] === this.userId &&
						imageData['role'] === 'decision'
					) {
						quantity++;
					}
				});

				if (quantity >= DecisionConst.MAX_ITEM) {
					this.isShowUploadContainer = false;
				} else if (
					this.decisionStatus !== CommonConst.SCREEN_STATUS.ACCEPT_CONFIRM &&
					this.decisionStatus !== CommonConst.SCREEN_STATUS.FINISH
				) {
					this.isShowUploadContainer = true;
				}
			}
		},

		/**
		 * Update notification red-dot base on screen status
		 * @param {String} newStatus  Screen Status
		 */
		decisionStatus(newStatus) {
			if (
				newStatus === CommonConst.SCREEN_STATUS.ACCEPT_CONFIRM ||
				newStatus === CommonConst.SCREEN_STATUS.DENY_CONFIRM
			) {
				this.$store.commit('setRedDotIndexs', [CommonConst.SCREEN_ID.DECISION]);
			} else if (newStatus === CommonConst.SCREEN_STATUS.WAITING_CONFIRM) {
				this.showFormatButton = false;
			} else if (newStatus === CommonConst.SCREEN_STATUS.DENY_CONFIRM) {
				this.showFormatButton = true;
			} else {
				const redDotIndexs = this.redDotIndexs.filter(
					(screenId) => screenId !== CommonConst.SCREEN_ID.DECISION
				);
				this.$store.commit('setRedDotIndexs', redDotIndexs);
			}
		},

		// isShowContent() {
		// 	this.setAutoHeightTextArea(
		// 		'decisionContent__imageItem__comment',
		// 		DecisionConst.TEXT_AREA_MIN_HEIGHT,
		// 		true
		// 	);
		// },

		orientation(newOrientation) {
			console.log({ newOrientation });
		},

		isControlScroll(newIsControlScroll) {
			console.log('CHECK SCROLL', newIsControlScroll);
			this.$store.commit('setIsControlScrollDecision', newIsControlScroll);
		},
		formatMenuData: {
			handler: function (newFormatMenuData) {
				if (this.decisionStatus !== CommonConst.SCREEN_STATUS.FINISH) {
					this.handleFormatMenuDataChange();
				}
			},
			deep: true,
		},
	},

	methods: {
		onImageOver(index) {
			this.displayedImageDatas[index].showDeleteBtn = true;
		},

		onImageLeave(index) {
			this.displayedImageDatas[index].showDeleteBtn = false;
		},
		handleFormatMenuDataChange() {
			this.isFormatMenuDataChanged = true;
			if (this.saveTimeout) {
				clearTimeout(this.saveTimeout);
			}
			this.saveTimeout = setTimeout(() => {
				if (this.isFormatMenuDataChanged) {
					this.onSaveInputContents();
					this.isFormatMenuDataChanged = false;
				}
			}, 1250);
		},
		async onPreviewButtonClick(imgData) {
			if (!imgData.imgUrl) return;

			if (imgData.type === 'pdf') {
				this.pdfPreviewer.url = imgData.imgUrl;
				console.log('this.this.pdfPreviewer', this.pdfPreviewer);
				this.selectedImageComment = imgData.comment;
				this.$bvModal.show(`${this.pdfPreviewModal}`);
				return;
			}
			if (imgData.imgUrl.includes('.svg')) {
				let base64Img = await this._toDataURL(imgData.imgUrl);
				this.selectedImageUrl = base64Img.replace(
					'binary/octet-stream',
					'image/svg+xml'
				);
			} else {
				this.selectedImageUrl = imgData.imgUrl;
			}
			this.selectedImageComment = imgData.comment;
			this.$bvModal.show(`${this.imagePreviewModal}`);
		},

		onDeleteImage(id) {
			this.currentDeletingImgId = id;
			this.$bvModal.show(`${this.confirmDeleteImgModalId}`);
		},

		onOpenCoCreate() {
			this.$emit('open-co-create');
		},

		openFormatMenu() {
			this.$bvModal.show(`${this.confirmOpenFormatModalId}`);
		},

		onHandleScroll() {
			this.isControlScroll = !this.isControlScroll;
			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'allow-scroll',
					content: this.isControlScroll,
				},
				room: this.projectId,
			});
			if (this.isControlScroll) {
				this.$nextTick(() => {
					this.$refs.aiPanel.sendRealTimeData();
				});
			}
		},

		async onConfirmOpenFormatMenu() {
			this.$store.commit('setIsAppProcessing', true);

			console.log('open format menu');
			this.isShowFormatMenu = true;
			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'show-hide-format-menu',
					content: this.isShowFormatMenu,
				},
				room: this.projectId,
			});

			await this._updateDecision(this.projectId, {
				show_format_menu: 1,
			});

			this.$store.commit('setIsAppProcessing', false);
			this.scrollToTop('#topDiv');
		},

		async onDeclineOpenFormatMenu() {
			this.$store.commit('setIsAppProcessing', true);

			this.isShowFormatMenu = false;

			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'show-hide-format-menu',
					content: this.isShowFormatMenu,
				},
				room: this.projectId,
			});

			await this._updateDecision(this.projectId, {
				show_format_menu: 0,
			});

			this.$store.commit('setIsAppProcessing', false);
			this.scrollToTop('#topDiv');
		},

		/**
		 * Change array data
		 * @param {String} type
		 * @param {Integer} idx
		 * @param {Integer} value
		 */
		onFormatMenuDataChange({ type, idx, value }) {
			this.formatMenuData[`${type}`][idx].value = value;
			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'format-menu-data-change-by-creator',
					content: this.formatMenuData,
				},
				room: this.projectId,
			});
			this.handleFormatMenuDataChange();
		},
		updateDecisionContent(newContent) {
			this.lable = newContent;
		},
		updateStatus(newContent) {
			if (newContent) {
				this.disableDecisionTextArea = true;
				this.showBottomButton = false;
				this.disableEditComment = true;
				this.isOpenFormatMenuDisabled = true;
				this.disableContentBtn = true;
				socketClient.send('data_transfer', {
					data: {
						user_id: this.userId,
						event_name: 'update-status-generate-text-by-creator',
						content: true,
						lable: this.lable,
					},
					room: this.projectId,
				});
			} else {
				this.disableDecisionTextArea = false;
				this.disableEditComment = false;
				this.isOpenFormatMenuDisabled = false;
				this.disableContentBtn = false;
				socketClient.send('data_transfer', {
					data: {
						user_id: this.userId,
						event_name: 'update-status-generate-text-by-creator',
						content: false,
						lable: this.lable,
					},
					room: this.projectId,
				});
			}
		},
		updateStatusComode(newContent, inputPrompt) {
			this.lable = inputPrompt;
			if (newContent) {
				this.disableDecisionTextArea = true;
				this.showBottomButton = false;
				this.disableEditComment = true;
				this.isOpenFormatMenuDisabled = true;
				this.disableContentBtn = true;
				socketClient.send('data_transfer', {
					data: {
						user_id: this.userId,
						event_name: 'update-status-generate-text-by-creator',
						content: true,
						lable: inputPrompt,
					},
					room: this.projectId,
				});
			} else {
				this.disableDecisionTextArea = false;
				this.disableEditComment = false;
				this.isOpenFormatMenuDisabled = false;
				this.disableContentBtn = false;
				socketClient.send('data_transfer', {
					data: {
						user_id: this.userId,
						event_name: 'update-status-generate-text-by-creator',
						content: false,
						lable: inputPrompt,
					},
					room: this.projectId,
				});
			}
		},
		/**
		 * Change text data
		 * @param {String} type
		 * @param {Integer} value
		 */
		onFormatMenuTextChange({ type, value }) {
			this.formatMenuData[`${type}`] = value;
			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'format-menu-data-change-by-creator',
					content: this.formatMenuData,
				},
				room: this.projectId,
			});
			this.handleFormatMenuDataChange();
		},

		async onConfirmDeleteImg() {
			this.$store.commit('setIsAppProcessing', true);

			let id = this.currentDeletingImgId;

			this.displayedImageDatas = this._removeIteamOfArrayWithId(
				this.displayedImageDatas,
				id,
				'imageId'
			);
			this.imageList = this._removeIteamOfArrayWithId(this.imageList, id, 'id');

			await this._updateDecision(this.projectId, {
				image_list: this.imageList,
				update_user: this.userId,
			});

			let cocreate = await this._getCoCreateData(this.projectId);
			let round_items =
				cocreate.round_items?.filter((item) => item.id !== id) || cocreate.round_items;
			let cross_items =
				cocreate.cross_items?.filter((item) => item.id !== id) || cocreate.cross_items;

			await this._updateCoCreateData(this.projectId, {
				cross_items,
				round_items,
			});

			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'decision_send_image_to_client',
					content: true,
				},
				room: this.projectId,
			});

			this.currentDeletingImgId = undefined;

			this.$store.commit('setIsAppProcessing', false);
		},

		_removeItemOfArray(array, index) {
			let originArray = [...array];
			originArray.splice(index, 1);
			return originArray;
		},

		/*
		 * Remove item of array with id
		 * @param {array} array - Array of data
		 * @param {string} id - Id of item want to remove
		 * @param {string} idKey - Key of id
		 */
		_removeIteamOfArrayWithId(array, id, key) {
			return array.filter((item) => item[key] !== id);
		},

		async onSendImage() {
			this.$store.commit('setIsAppProcessing', true);

			let id = new Date().getTime();
			let newImgData = {
				imageId: id,
				image: this.imgFileKey,
				comment: this.imgComment,
				showDeleteBtn: false,
				showEditComment: false,
				imgUrl: this.imgDataString,
				userId: this.userId,
				type: this.getFileExtention(this.imgFileKey),
				fileName: this.getFileName(this.imgFileKey),
				role: 'decision',
			};

			this.$refs.imageUpload._revertData();
			this.$refs.imageComment._revertData();
			this.displayedImageDatas.push(newImgData);

			let imageUpdated = {
				id: id,
				image: this.imgFileKey,
				comment: this.imgComment,
				user_id: this.userId,
				role: 'decision',
			};
			this.imageList.push(imageUpdated);

			await this._updateDecision(this.projectId, {
				image_list: this.imageList,
				update_user: this.userId,
			});

			this.imgDataString = '';
			this.imgComment = '';
			this.imgFileKey = '';

			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'decision_send_image_to_client',
					content: true,
				},
				room: this.projectId,
			});

			this.$store.commit('setIsAppProcessing', false);
			this.setAutoHeightTextArea(
				'decisionContent__imageItem__comment',
				DecisionConst.TEXT_AREA_MIN_HEIGHT
			);
		},

		async onSavePredictedImage(imageInfo) {
			this.$store.commit('setIsAppProcessing', true);

			let id = new Date().getTime();
			let newImgData = {
				imageId: id,
				image: imageInfo.key,
				comment: imageInfo.comment,
				showDeleteBtn: false,
				showEditComment: false,
				imgUrl: imageInfo.url,
				userId: this.userId,
				type: this.getFileExtention(imageInfo.url),
				fileName: this.getFileName(imageInfo.url),
				role: 'decision',
			};

			this.displayedImageDatas.push(newImgData);

			let imageUpdated = {
				id: id,
				image: imageInfo.key,
				comment: imageInfo.comment,
				user_id: this.userId,
				role: 'decision',
			};
			this.imageList.push(imageUpdated);

			await this._updateDecision(this.projectId, {
				image_list: this.imageList,
				update_user: this.userId,
			});

			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'decision_send_image_to_client',
					content: true,
				},
				room: this.projectId,
			});

			this.$store.commit('setIsAppProcessing', false);
		},

		/**
		 * Remove image data when click close button
		 */
		onRemoveImageData() {
			this.imgDataString = '';
			this.imgFileKey = '';
		},

		onCreate() {
			this.isCreateQuestion = true;
			this.$bvModal.show('question-modal');
		},

		getFileExtention(key) {
			if (key) {
				const arr = key.split('.');
				return arr[arr.length - 1];
			}
		},

		getFileName(key) {
			if (key) {
				const arr = key.split('/');
				return arr[arr.length - 1];
			}
		},

		/**
		 * Update Answer from Client
		 * @param {Boolean} data: Get client answered status by socket io
		 */
		async onUpdateSelectedQuestion(data) {
			this.$store.commit('setIsAppProcessing', true);

			if (data) {
				await this._getDecisionData(this.projectId, {
					isUpdateDicisionContent: false,
					isUpdateFormatMenu: false,
				});
				socketClient.send('update_question_list', {
					userId: this.userId,
					projectId: this.projectId,
					content: true,
				});
				this.$refs.questionModal.onClose();
			}

			this.$store.commit('setIsAppProcessing', false);
		},

		async onSave() {
			let response = await ProjectService.get(this.projectId);
			if (response.data['only_decision'] === 1) {
				this.showBottomDecision = true;
			} else {
				this.showBottomButton = true;
			}
			this.onSaveInputContents();
		},
		async onSaveInputContents() {
			this.$store.commit('setIsAppProcessing', true);

			let decisionUpdateData = {
				decision_content: this.decisionContent,
				logo_name_origin: this.formatMenuData.logoNameOrigin,
				concept: this.formatMenuData.concept,
				design_tastes: this.formatMenuData.designTastes,
				color_palettes: this.formatMenuData.colorPalettes,
				fonts: this.formatMenuData.fonts,
				logo_formats: this.formatMenuData.logoFormats,
				update_user: this.userId,
				status: CommonConst.SCREEN_STATUS.SAVE,
			};
			let updateResult = await this._updateDecision(this.projectId, decisionUpdateData);
			if (updateResult) {
				// this.showBottomButton = true;
				this.showStatusBadge = false;
				this.decisionStatus = CommonConst.SCREEN_STATUS.SAVE;
			}
			socketClient.send('update_question_list', {
				userId: this.userId,
				projectId: this.projectId,
				content: this.decisionContent,
			});
			// this.$refs.questionModal.onClose();
			await this._getDecisionData(this.projectId, {
				isUpdateDicisionContent: false,
				isUpdateFormatMenu: false,
			});

			// this.$bvModal.show(this.plainModalId);

			this.$store.commit('setIsAppProcessing', false);
		},

		/**
		 * Trigger when delete one Question
		 * @param {Object} data: Selected Question's data
		 */
		onDeleteQuestion(data) {
			this.currentDeletingQuestion = data;
			this.$bvModal.show(`${this.confirmDeleteQuestionModalId}`);
		},

		async onConfirmDeleteQuestion() {
			this.$store.commit('setIsAppProcessing', true);

			let question = this.currentDeletingQuestion;

			this.questionList = this._removeItemOfArray(this.questionList, question.index);

			await this._deleteDecisionDetail(question.questionId);

			socketClient.send('update_question_list', {
				userId: this.userId,
				projectId: this.projectId,
				content: true,
			});

			this.currentDeletingQuestion = undefined;

			this.$store.commit('setIsAppProcessing', false);
		},

		/**
		 * Upload image to S3 when finish reading file
		 */
		async onUploadImage(data) {
			this.imgDataString = data.content;

			this.imgFileKey = data.fileKey;
		},

		onInputRemark(content) {
			this.imgComment = content.comment;
		},

		onConfirm() {
			this.$bvModal.show(`${this.selectiveModalId}`);
		},

		/**
		 * Trigger when click in Bottom Button
		 */
		async onSendConfirmRequire() {
			this.$store.commit('setIsAppProcessing', true);

			// Disable scroll when send confirm require
			this.isControlScroll = false;
			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'allow-scroll',
					content: this.isControlScroll,
				},
				room: this.projectId,
			});

			let decisionInfo = {
				status: CommonConst.SCREEN_STATUS.WAITING_CONFIRM,
				decision_content: this.decisionContent,
			};
			let updateResult = await this._updateDecision(this.projectId, decisionInfo);
			if (updateResult) {
				this.showStatusBadge = true;
				this.badgeContent = 'クライアント確定待ち';
				this.badgeType = 'notify';
				this.showBottomButton = false;
				this.decisionStatus = CommonConst.SCREEN_STATUS.WAITING_CONFIRM;

				socketClient.send('data_transfer', {
					data: {
						user_id: this.userId,
						event_name: 'decision_send_confirm',
						content: true,
					},
					room: this.projectId,
				});

				await this._getDecisionData(this.projectId, {
					isUpdateDicisionContent: false,
					isUpdateFormatMenu: false,
				});

				// //send mail to creator case 28
				// this.mailTitle = `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］「詳細決め」確定依頼を送りました`;
				// this.mailContent = `「詳細決め」の\n確定依頼を送りました。`;
				// this.subject = `【CoMoDe】[${this.clientInfo['client_name']}/${this.projectInfo['project_name']}]「詳細決め」確定依頼を送りました`;
				// this.isSendCreator = true;
				// sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				// 	this.projectInfo['responsible_user'],
				// ]);
				// this.mailTitle = `[CoMoDe] ${this.projectInfo['project_name']} 「詳細決め」の確定依頼が届きました`;
				// this.mailContent = `「詳細決め」の\n確定依頼が届きました。`;
				// this.subject = `【CoMoDe】[${this.projectInfo['project_name']}]「詳細決め」の確定依頼が届きました`;
				// this.isSendCreator = false;
				// sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				// 	this.projectInfo['client_id'],
				// ]);
			}

			this.$store.commit('setIsAppProcessing', false);
		},

		/**
		 * Trigger when click in Question item
		 * @param {String} data:  Selected item data
		 */
		async onSelect(data) {
			this.$store.commit('setIsAppProcessing', true);

			this.isCreateQuestion = data['isCreatNew'];
			const decisionData = { ...this.decisionData };
			const decisionDetails = decisionData['decision_details'];
			const selectedQuestionObj = decisionDetails.find((x) => x.id === data['id']);
			const selectedQuestionImg = selectedQuestionObj['image_file_name'];

			/* eslint no-mixed-spaces-and-tabs: "off" */
			const selectedQuestionImgURL = selectedQuestionImg
				? await this._getPreviewImgUrl(
						selectedQuestionImg,
						CommonConst.ONE_WEEK_SECONDS
				  )
				: '';

			const selectedQuestion = {
				answeredStatus: Boolean(selectedQuestionObj['answered_flag']),
				readedStatus: Boolean(selectedQuestionObj['readed_flag']),
				answeredContents: selectedQuestionObj['answer_content'],
				comment: selectedQuestionObj['comment'],
				questionContent: selectedQuestionObj['question'],
				imageUrl: selectedQuestionImgURL,
				answerDeadline: selectedQuestionObj['answer_deadline'],
				questionIndex: selectedQuestionObj['id'],
				imageFileName: selectedQuestionObj['image_file_name'],
			};

			if (selectedQuestion.answeredStatus) {
				//Update readed_flag when click in answered question
				selectedQuestionObj['readed_flag'] = 1;
				let response = await DecisionDetailService.update(selectedQuestionObj['id'], {
					doc_content: selectedQuestionObj,
				});
				if (response.status !== 200) {
					throw 'Update decision detail failed';
				}
				this.$emit('on-read-decision-question');
				selectedQuestion['readedStatus'] = true;
				await this._getDecisionData(this.projectId, {
					isUpdateDicisionContent: false,
					isUpdateFormatMenu: false,
				});
			}
			this.selectedQuestion = selectedQuestion;
			this.$bvModal.show('question-modal');
			this.$store.commit('setIsAppProcessing', false);
		},

		/**
		 * Update question list when create new question
		 * @param {Booleen} data
		 */
		async onUpdateNewQuestion(data) {
			this.$store.commit('setIsAppProcessing', true);

			console.log('Update new question');
			if (data) {
				let decisionData = { ...this.decisionData };
				this._filterQuestionList(decisionData);
				await this._getDecisionData(this.projectId, {
					isUpdateDicisionContent: false,
					isUpdateFormatMenu: false,
				});
				socketClient.send('update_question_list', {
					userId: this.userId,
					projectId: this.projectId,
					content: true,
				});
				let deadline = moment(data['answer_deadline']).format('YYYY年MM月DD日');
				// send mail case 25
				this.mailTitle = `[CoMoDe] ${this.projectInfo['project_name']} 要検討事項が届きました`;
				this.mailContent = `要検討事項が届きました。\n(回答期限 ${deadline})`;
				this.subject = `【CoMoDe】[${this.projectInfo['project_name']}] 要検討事項が届きました`;
				this.isSendCreator = false;
				sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
					this.projectInfo['client_id'],
				]);
			}

			this.$store.commit('setIsAppProcessing', false);
		},

		/**
		 * Text area trigger
		 */
		onInputDecision() {
			if (this.saveTimeout) {
				clearTimeout(this.saveTimeout);
			}
			this.saveTimeout = setTimeout(() => {
				this.onSaveInputContents();
			}, 1250);
			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'decision_content_edit_by_creator',
					content: this.decisionContent,
				},
				room: this.projectId,
			});
		},

		/**
		 * Filter question list
		 * @param {Object} decisionData
		 */
		_filterQuestionList(decisionData) {
			let decisionDetails = decisionData['decision_details'] || [];
			let questionList = [];
			for (const element of decisionDetails) {
				let defaultQuestion = { ...DEFAULT_ITEM };
				//if answered_flag = 1 is mean client had been answer the question then isChanged = true else isChanged = false
				defaultQuestion['isChanged'] = Boolean(element['answered_flag']);
				//if readed_flag = 1 is mean creator had been read the answer then isReaded = true else isReaded = false
				defaultQuestion['isReaded'] = Boolean(element['readed_flag']);
				defaultQuestion['title'] = element['question'];
				if (defaultQuestion['isChanged'] && !defaultQuestion['isReaded']) {
					defaultQuestion['dateData'] = 'クライアントから回答がきています';
					defaultQuestion['isColorChanged'] = true;
				} else if (defaultQuestion['isReaded']) {
					defaultQuestion['isShowIcon'] = true;
					defaultQuestion['dateData'] = '回答済み';
				} else {
					defaultQuestion['dateData'] = this._filterDate(
						element['answer_deadline'],
						defaultQuestion['isChanged']
					);
					defaultQuestion['isColorChanged'] = false;
				}
				defaultQuestion['nonfilterDateData'] = element['answer_deadline'];
				defaultQuestion['itemId'] = element['id'];
				defaultQuestion['commentContent'] = element['comment'];

				questionList.push(defaultQuestion);
			}
			this.questionList = questionList;
			// console.log('UPDATE QUESTION LIST', this.questionList);
		},

		/**
		 * Filter Date to String
		 * @param {DateTime} date
		 * @returns
		 */
		_filterDate(date, status) {
			if (date) {
				let momentDate = moment(date);
				let year = momentDate.format('YYYY');
				let month = momentDate.format('MM');
				let day = momentDate.format('DD');
				if (!status) {
					return `回答期限 [${year} 年 ${month} 月 ${day} 日]`;
				} else {
					return `${year} 年 ${month} 月 ${day}日に更新`;
				}
			}
		},

		/**
		 * Disable button when client confirm
		 */
		_disableElements() {
			this.showBottomButton = false;
			this.isSaveButtonShowed = false;
			this.disableDecisionTextArea = true;
			this.isShowUploadContainer = false;
			this.isShowQuestionDeleteButton = false;
		},

		/**
		 * Convert decisionData to pairs of image
		 * @param {Object} data Decision data
		 */
		async _splitDecisionDataToImageArray(data) {
			let displayedImageDatas = [];

			if (data && data.length > 0) {
				data.map((imageData) => {
					displayedImageDatas.push({
						imageId: imageData['id'] ? imageData.id : null,
						imageKey: imageData['image'] ? imageData.image : null,
						comment: imageData.comment,
						imgUrl: imageData['url'] ? imageData.url : null,
						showDeleteBtn: false,
						showEditComment: false,
						userId: imageData['user_id'],
						role: imageData.role,
						type: this.getFileExtention(imageData['image']),
						fileName: this.getFileName(imageData['image']),
					});
				});
				this.displayedImageDatas = await this._getPreviewImage(displayedImageDatas);
			} else this.displayedImageDatas = displayedImageDatas;
		},

		/**
		 * get preview image
		 * @param {Array} data displayedImageDatas
		 */
		async _getPreviewImage(data) {
			let displayedImageDatas = [...data];
			// get preview image url
			for (let imageData of displayedImageDatas) {
				if (imageData.imageKey) {
					let imgUrl = await this._getPreviewImgUrl(
						imageData.imageKey,
						CommonConst.ONE_WEEK_SECONDS
					);
					imageData.imgUrl = imgUrl;
				}
			}

			return displayedImageDatas.filter((imageData) => {
				return imageData.imgUrl !== null;
			});
		},

		/**
		 * Get Decision data
		 * @param {Integer} projectId project ID
		 * @param {Object} {isUpdateDicisionContent, isUpdateFormatMenu} indicates the possibility of updating DecisionData and FormatMenuData
		 * @returns
		 */
		async _getDecisionData(projectId, { isUpdateDicisionContent, isUpdateFormatMenu }) {
			let response = await DecisionService.get(projectId);

			if (!response || response.status !== 200) {
				throw 'Get Decision failed';
			}

			const decisionData = response.data;

			if (response.data['confirm_flag'] === 1) {
				this._disableElements();
			} else {
				this.isShowUploadContainer = true;
				this.allowAdditionsQuestion = true;
				this.isShowQuestionDeleteButton = true;
			}

			this.decisionData = decisionData;
			this.imageList = decisionData['image_list'];

			if (isUpdateDicisionContent) {
				this.decisionContent = decisionData['decision_content'];
			}
			if (isUpdateFormatMenu) {
				this.formatMenuData = {
					logoNameOrigin: decisionData['logo_name_origin'],
					concept: decisionData['concept'],
					designTastes: decisionData['design_tastes'] || DecisionConst.DESIGN_TASTES,
					colorPalettes: decisionData['color_palettes'] || DecisionConst.COLOR_PALETTES,
					fonts: decisionData['fonts'] || DecisionConst.FONTS,
					logoFormats: decisionData['logo_formats'] || DecisionConst.LOGO_FORMATS,
				};
			}

			this.checkShowFormatMenu(decisionData);

			if (this.decisionContent) this.isQuotationSaved = true;
			this.decisionId = decisionData['id'];
			this.decisionStatus = decisionData['status'];
			if (decisionData['status'] !== CommonConst.SCREEN_STATUS.FINISH) {
				this.isShowScrollSetupButton = true;
			} else {
				this.isShowScrollSetupButton = false;
			}

			this.dummyReason = decisionData['reason'];

			this.checkShowFormatMenuButton(decisionData['status']);
			this._checkDecisionStatus(this.decisionStatus);

			this._filterQuestionList(this.decisionData);

			await this._splitDecisionDataToImageArray(this.imageList);

			this.setAutoHeightTextArea(
				'decisionContent__imageItem__comment',
				DecisionConst.TEXT_AREA_MIN_HEIGHT,
				false
			);
		},

		checkShowFormatMenu(decisionData) {
			const checkShowFormatMenu = Boolean(decisionData['show_format_menu']);

			this.isShowFormatMenu = checkShowFormatMenu;
			this.isOpenFormatMenuDisabled = checkShowFormatMenu;

			if (
				!decisionData['status'] ||
				decisionData['status'] === CommonConst.SCREEN_STATUS.SAVE ||
				decisionData['status'] === CommonConst.SCREEN_STATUS.DENY_CONFIRM ||
				decisionData['status'] === CommonConst.SCREEN_STATUS.FINISHED
			)
				this.isOpenFormatMenuDisabled = false;
		},

		checkShowFormatMenuButton(decisionStatus) {
			if (
				!decisionStatus ||
				decisionStatus === CommonConst.SCREEN_STATUS.DENY_CONFIRM ||
				decisionStatus === CommonConst.SCREEN_STATUS.SAVE
			) {
				this.showFormatButton = true;
			}
		},

		/**
		 * Update Decision
		 * @param {Integer} projectId Project ID
		 * @param {Object} decisionUpdateData updating data
		 */
		async _updateDecision(projectId, decisionUpdateData) {
			try {
				let response = await DecisionService.update(projectId, {
					doc_content: decisionUpdateData,
				});
				if (!response || response.status !== 200) {
					throw 'Update Decision failed';
				}
				console.log('%c Update Decision successfully!', 'color: green');
				return true;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		/**
		 * Upload image
		 * @param {Object} params request objects
		 * @returns uploaded file key
		 */
		async _uploadImage(params) {
			try {
				let response = await TransferService.post(params);
				if (!response || response.status !== 200) {
					throw 'Upload Image failed!';
				}

				console.log('%c Upload image successfully!', 'color: green');
				return response.data.key;
			} catch (error) {
				console.log(error);
			}
		},

		/**
		 * Get image preview url
		 * @param {String} imgKey Image key to get image url
		 * @param {String} expiration The lifetime of the url in seconds
		 * @returns image url
		 */
		async _getPreviewImgUrl(imgKey, expiration) {
			try {
				let response = await TransferService.get(imgKey, expiration);
				if (!response || response.status !== 200) {
					throw 'Get image url failed!';
				}

				console.log('%c Get image url successfully!', 'color: green');
				return response.data.link;
			} catch (error) {
				console.log(error);
			}
		},

		/**
		 * Check decision screen status
		 * @param {String} status
		 */
		_checkDecisionStatus(status) {
			if (
				status === CommonConst.SCREEN_STATUS.SAVE &&
				this.decisionData['confirm_flag'] !== 1
			) {
				// this.showBottomButton = true;
				this.showStatusBadge = false;
			} else if (status === CommonConst.SCREEN_STATUS.WAITING_CONFIRM) {
				this.showStatusBadge = true;
				this.showBottomButton = false;
				this.badgeType = 'notify';
				if (this.dummyReason !== '' || this.dummyReason !== null) {
					this.badgeContent = 'クライアント承認待ち';
				} else {
					this.badgeContent = 'クライアント確定待ち';
				}
				this.disableDecisionTextArea = true;
			} else if (
				status === CommonConst.SCREEN_STATUS.DENY_CONFIRM &&
				this.decisionData['confirm_flag'] !== 1
			) {
				this.showStatusBadge = true;
				this.badgeType = 'warning';
				this.badgeContent = 'クライアント確定されませんでした。';
				this.showBottomButton = true;
				this.disableDecisionTextArea = false;
			} else if (
				status === CommonConst.SCREEN_STATUS.ACCEPT_CONFIRM &&
				this.decisionData['confirm_flag'] === 1
			) {
				this.buttonType = 'direct-screen-btn';
				this.buttonName = 'コンセプト仕上げに進む';
				this.showStatusBadge = false;
				this.showBottomButton = true;
				this.disableDecisionTextArea = true;
				this.isShowUploadContainer = false;
				this.disableEditComment = true;
			} else if (status === CommonConst.SCREEN_STATUS.FINISH) {
				this.showStatusBadge = false;
				this.disableDecisionTextArea = true;
				this.showBottomButton = false;
				this.disableEditComment = true;
				this.disableContentBtn = true;
			}
		},

		async onDirectClick() {
			this.$store.commit('setIsAppProcessing', true);

			let confirmInfo = {
				screenId: DecisionConst.DECISION_SCREEN_ID,
				confirmInfo: true,
			};
			await this._updateDecision(this.projectId, {
				status: CommonConst.SCREEN_STATUS.FINISH,
				confirm_flag: 1,
			});
			this.decisionStatus = CommonConst.SCREEN_STATUS.FINISH;
			if (this.projectInfo['status'] === DecisionConst.DECISION_SCREEN_ID) {
				this.$emit('on-send-confirm-info', confirmInfo);
				socketClient.send('data_transfer', {
					data: {
						user_id: this.userId,
						event_name: 'decision_direct',
						content: this.directLink,
					},
					room: this.projectId,
				});
				this.$emit('on-update-project-status', DecisionConst.DECISION_SCREEN_ID + 1);
				await this.updateProjectStatus(
					this.projectId,
					DecisionConst.DECISION_SCREEN_ID + 1
				);
				this.decisionStatus = CommonConst.SCREEN_STATUS.FINISH;
			}

			// // send mail case 32
			// this.mailTitle = `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］「クリエイティブ」スタート`;
			// this.mailContent = `「クリエイティブ」を\nスタートします。`;
			// this.subject = `【CoMoDe】[${this.clientInfo['client_name']}/${this.projectInfo['project_name']}]「クリエイティブ」スタート`;
			// this.isSendCreator = true;
			// sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
			// 	this.projectInfo['responsible_user'],
			// ]);
			// this.mailTitle = `[CoMoDe] ${this.projectInfo['project_name']} 「クリエイティブ」スタート`;
			// this.mailContent = `「クリエイティブ」を\nスタートします。`;
			// this.subject = `【CoMoDe】[${this.projectInfo['project_name']}]「クリエイティブ」スタート`;
			// this.isSendCreator = false;
			// sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
			// 	this.projectInfo['client_id'],
			// ]);
			this.$store.commit('setIsControlScrollDecision', false);
			this.$router.push(this.directLink);
			this.$store.commit('setIsAppProcessing', false);
		},
		async onDirectionButtonClick() {
			this.$store.commit('setIsAppProcessing', true);
			let confirmInfo = {
				screenId: DecisionConst.DECISION_SCREEN_ID,
				confirmInfo: true,
			};
			await this._updateDecision(this.projectId, {
				status: CommonConst.SCREEN_STATUS.FINISH,
				confirm_flag: 1,
			});
			this.decisionStatus = CommonConst.SCREEN_STATUS.FINISH;

			this.$emit('on-send-confirm-info', confirmInfo);
			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'decision_only',
					content: 'finish',
				},
				room: this.projectId,
			});
			this.$emit('on-update-project-status', 5);
			await this.updateProjectStatus(this.projectId, 5);
			this.decisionStatus = CommonConst.SCREEN_STATUS.FINISH;

			await this._updateProject(this.projectId, {
				creator_finished: 1,
				client_finished: 1,
			});
			let projectInfo = {
				...this.projectInfo,
				creator_finished: 1,
				client_finished: 1,
			};
			this.$store.commit('setProjectInfo', projectInfo);

			this.$router.push('finish');
			this.$store.commit('setIsAppProcessing', false);
		},
		async _updateProject(projectId, content) {
			try {
				let response = await ProjectService.update(projectId, content);
				if (!response || response.status !== 200) {
					throw 'Upload Project failed!';
				}
				console.log('%c Upload Project successfully!', 'color: green');
			} catch (error) {
				console.log(`%c ${error}`, 'color: red');
			}
		},
		/**
		 * Delete image by image key
		 * @param {String} imgKey Image key
		 */
		async _deleteImage(imgKey) {
			try {
				let response = await TransferService.delete(imgKey);
				if (!response || response.status !== 200) {
					throw 'Delete image failed!';
				}

				console.log('%c Delete image successfully!', 'color: green');
			} catch (error) {
				console.log(error);
			}
		},

		/**
		 * Delete Decision Detail by id
		 * @param {Integer} id DecisionDetail Id
		 */
		async _deleteDecisionDetail(id) {
			try {
				let response = await DecisionDetailService.delete(id);
				if (!response || response.status !== 200) {
					throw 'Delete Decision Detail failed!';
				}

				console.log('%c Delete Decision Detail successfully!', 'color: green');
			} catch (error) {
				console.log(error);
			}
		},
		async _getCoCreateData(projectId) {
			try {
				let response = await CoCreateService.get(projectId);
				if (!response || response.status !== 200) {
					throw 'Get Decision failed';
				}

				if (Object.keys(response.data).length === 0) {
					await this._createCoCreateData();
				}
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},
		/**
		 * Update co-create data
		 */
		async _updateCoCreateData(id, data) {
			try {
				let response = await CoCreateService.update(id, data);
				if (!response || response.status !== 200) {
					throw 'Update Co-Create failed';
				}
				console.log('%c Update Co-Create successfully', 'color: green');
				return true;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		/**
		 * Edit comment
		 * @param {Integer} index index of displayedImageDatas
		 * @param {Integer} imageId image id
		 * @returns
		 */
		async onEditComment(index, imageId) {
			this.$store.commit('setIsAppProcessing', true);

			let editedComment = this.displayedImageDatas[index].comment;
			let editedImageRole = this.displayedImageDatas[index].role;
			let tempImageList = [...this.imageList];

			let imageIndex = tempImageList.findIndex((image) => image.id === imageId);

			if (imageIndex < 0) {
				console.log('%c No image found', 'color: red');
				this.$store.commit('setIsAppProcessing', false);

				return;
			}

			// Update Dicision
			tempImageList[imageIndex].comment = editedComment;
			await this._updateDecision(this.projectId, {
				image_list: tempImageList,
				update_user: this.userId,
			});
			await this._getDecisionData(this.projectId, {
				isUpdateDicisionContent: false,
				isUpdateFormatMenu: false,
			});

			// Update CoCreate if not decision
			if (editedImageRole !== 'decision') {
				let { round_items } = await this._getCoCreateData(this.projectId);
				round_items.map((round) => {
					if (round.id === imageId) {
						round.comment = editedComment;
					}
				});
				await this._updateCoCreateData(this.projectId, {
					round_items,
				});
			}

			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'decision_send_image_to_client',
					content: true,
				},
				room: this.projectId,
			});

			this.$store.commit('setIsAppProcessing', false);
		},
		/**
		 * Cancel edit comment
		 * @param {Integer} index index of displayedImageDatas
		 */
		async onCancel(index) {
			this.$store.commit('setIsAppProcessing', true);

			this.displayedImageDatas[index].showEditComment = false;
			await this._getDecisionData(this.projectId, {
				isUpdateDicisionContent: false,
				isUpdateFormatMenu: false,
			});

			this.$store.commit('setIsAppProcessing', false);
		},
		/**
		 * Only edit focused comment
		 * @param {Integer} index index of displayedImageDatas
		 */
		onCommentFocus(e, index) {
			if (e.target.readOnly) return;

			this.displayedImageDatas.map((imageData, idx) => {
				imageData.showEditComment = idx === index ? true : false;
			});
		},

		onClickingSelectedArea(id) {
			socketClient.send('scroll_to_area', {
				data: {
					id: id,
				},
				room: this.projectId,
			});
		},
		async _toDataURL(url) {
			return new Promise((resolve, reject) => {
				var xhr = new XMLHttpRequest();
				xhr.onload = function () {
					var reader = new FileReader();
					reader.onloadend = function () {
						resolve(reader.result);
					};
					reader.readAsDataURL(xhr.response);
				};
				xhr.onerror = () => {
					reject({
						status: this.status,
						statusText: xhr.statusText,
					});
				};
				xhr.open('GET', url);
				xhr.responseType = 'blob';
				xhr.send();
			});
		},
	},

	async created() {
		socketClient.listen('new_data_transfer', async (data) => {
			switch (data?.event_name) {
				case 'send_images_to_decision':
					this.$store.commit('setIsAppProcessing', true);

					var options = {
						isUpdateDicisionContent: false,
						isUpdateFormatMenu: false,
					};

					if (data?.content?.updateDecisionContent) {
						options = {
							isUpdateDicisionContent: true,
							isUpdateFormatMenu: false,
						};
					}

					await this._getDecisionData(this.projectId, options);

					this.$store.commit('setIsAppProcessing', false);
					break;

				case 'decision_content_edit_by_client':
					this.decisionContent = data?.content;
					break;
				case 'format-menu-data-change-by-client':
					if (data.content) {
						this.formatMenuData = data.content;
					}
					break;
				case 'decision_answer_confirm':
					this.$store.commit('setIsAppProcessing', true);

					this.decisionData['confirm_flag'] = data.content?.confirm_flag;
					this._checkDecisionStatus(data.content?.status);
					this.decisionStatus = data.content?.status;
					this.dummyReason = data.content?.reason;
					await this._getDecisionData(this.projectId, {
						isUpdateDicisionContent: false,
						isUpdateFormatMenu: false,
					});

					this.$store.commit('setIsAppProcessing', false);
					break;

				case 'decision_send_image_to_creator':
					this.$store.commit('setIsAppProcessing', true);

					if (data.content) {
						await this._getDecisionData(this.projectId, {
							isUpdateDicisionContent: false,
							isUpdateFormatMenu: false,
						});
					}

					this.$store.commit('setIsAppProcessing', false);
					break;
				case 'check_scroll_status':
					socketClient.send('data_transfer', {
						data: {
							user_id: this.userId,
							event_name: 'allow-scroll',
							content: this.isControlScroll,
						},
						room: this.projectId,
					});
					if (this.isControlScroll) {
						this.$refs.aiPanel.sendRealTimeData();
					}
					break;
				case 'update-status-generate-text-by-client':
					if (data.content) {
						this.disableDecisionTextArea = true;
						this.showBottomButton = false;
						this.disableEditComment = true;
						this.isOpenFormatMenuDisabled = true;
						this.disableContentBtn = true;
						this.lable = data.lable;
					} else {
						this.disableDecisionTextArea = false;
						this.disableEditComment = false;
						this.isOpenFormatMenuDisabled = false;
						this.disableContentBtn = false;
						this.lable = data.lable;
					}
					break;
				default:
					break;
			}
		});

		socketClient.listen('new_question_list_updated', async (data) => {
			this.$store.commit('setIsAppProcessing', true);

			if (data) {
				console.log('Update Decision Data');
				await this._getDecisionData(this.projectId, {
					isUpdateDicisionContent: false,
					isUpdateFormatMenu: false,
				});
			}

			this.$store.commit('setIsAppProcessing', false);
		});
	},

	async mounted() {
		this.$store.commit('setIsAppProcessing', true);
		moment.locale('ja');
		if (this.projectId) {
			await this._getDecisionData(this.projectId, {
				isUpdateDicisionContent: true,
				isUpdateFormatMenu: true,
			});
		}

		this.$nextTick(function () {
			// Code that will run only after the
			// entire view has been rendered
			this.$store.commit('setIsAppProcessing', false);
			this.isShowContent = true;
		});
	},
};
