import ApiService from './api.service';

const serviceURL = 'cocreate';
const CoCreateService = {
	/**
	 * Acquire cocreate by projectID
	 * @param {Integer} projectId
	 * @returns {Object} Schedule details
	 */
	get(projectId) {
		return ApiService.get(`${serviceURL}`, `${projectId}`);
	},

	/**
	 * Create new CoCreate record
	 * @param {Object} body CoCreate Object
	 * @returns {Object} include success message
	 */
	post(body) {
		return ApiService.post(`${serviceURL}/`, body);
	},
	/**
	 * Update search keyword, categories
	 * @param {Integer} projectId: Project ID
	 * @param {Object} contents: update content
	 * @returns
	 */
	update(projectId, contents) {
		let body = {
			doc_content: contents,
		};
		return ApiService.put(`${serviceURL}/${projectId}`, body);
	},
};

export default CoCreateService;
