import DecisionConst from '@/constants/DecisionConst';
import ComodeAiConst from '@/constants/ComodeAiConst';

import ImageSaveModal from '@/components/PreviewModal/ImagePreviewModal.vue';
import MobilePreviewModal from '@/components/Modal/PreviewPredictImage/PreviewPredictImage.vue';
import NotificationModal from '@/components/NotificationModal/NotificationModal.vue';

import TransferService from '@/services/API/transfer.service';
import OpenAIService from '@/services/API/openai.service';
import TranslateService from '@/services/API/translate.service';

import axios from 'axios';
import { mapState } from 'vuex';
import socketClient from '@/services/SOCKET';

const PROGRESS_READER_LIMIT = 30;
const PROGRESS_POST_LIMIT = 60;

export default {
	name: 'AIPanel',
	components: {
		ImageSaveModal,
		MobilePreviewModal,
		NotificationModal,
	},
	props: {
		formatMenuData: Object,
		inputPrompt: String,
		isShowFormatMenu: Boolean,
		decisionContent: String,
		isShowScrollButton: {
			type: Boolean,
			default: false,
		},
		isControlScroll: {
			type: Boolean,
			default: true,
		},
		disabled: {
			type: Boolean,
		},
	},
	data() {
		return {
			KEYWORD_TITLES: DecisionConst.KEYWORD_TITLES,
			isShowBackdrop: new Array(4).fill(false),
			image: {
				url: '',
				comment: '',
			},
			imgPreviewModalId: 'predicted-image-modal-id',
			mobilePreviewModalId: 'preview-predict-modal',

			errorModalId: 'error-modal-id',
			errorModalContent:
				'現在Co-mode AIはご利用いただけません。\n少し経ってからお試しください。',

			isImageSaved: new Array(4).fill(false),
			processValue: 0,
			predictionStatus: DecisionConst.PREDICTION_STATUS.init,
			PREDICTION_STATUS: DecisionConst.PREDICTION_STATUS,
			progressBarInterval: null,
			imageList: [],
			selectedIdx: 0,
			titleProgressBar: '',
			statusbuttonText: true,
		};
	},
	watch: {
		// inputPrompt() {
		// 	this.sendSocketEvent(0);
		// },
		decisionContent() {
			if (!this.decisionContent) this.statusbuttonText = true;
			else this.statusbuttonText = false;
		},
	},
	computed: {
		...mapState(['projectId', 'userId', 'projectInfo']),
		isCreator() {
			return this.userId === this.projectInfo.responsible_user;
		},
	},
	methods: {
		onClickingSelectedArea(id) {
			this.$emit('on-clicking-selected-area', id);
		},
		clearAll() {
			this.inputPrompt = '';
			this.image.url = '';
			this.processValue = 0;
			clearInterval(this.progressBarInterval);
			this.predictionStatus = this.PREDICTION_STATUS.init;
		},
		showBackdrop(index) {
			this.isShowBackdrop.splice(index, 1, true);
		},
		hideBackdrop(index) {
			this.isShowBackdrop.splice(index, 1, false);
		},
		async generateText() {
			try {
				this.titleProgressBar = 'テキスト生成中';
				this.intervalProgressBar();
				this.$emit('update-status', (this.status = true), this.inputPrompt);
				const response = await OpenAIService.generateTextComodeAI(this.decisionContent);
				this.inputPrompt = response.data.choices[0].message.content.trim();
				this.intervalProgressBar(100);
				this.$emit('update-status', (this.status = false), this.inputPrompt);
				this.$emit('update-decision-content', this.inputPrompt);
				this.resetValue();
			} catch (error) {
				if (!error.response) {
					this.errorModalContent = ComodeAiConst.NO_INTERNET;
				} else {
					let errorData = error.response.data.error;
					if (errorData.code == 'billing_hard_limit_reached') {
						this.errorModalContent = ComodeAiConst.BILLING_HARD_LIMIT_REACHED;
					} else if (errorData.message.includes('text that is not allowed')) {
						this.errorModalContent = ComodeAiConst.NSFW_TEXT;
					} else {
						this.errorModalContent = ComodeAiConst.GENERAL_ERROR;
					}
				}
				this.$bvModal.show(this.errorModalId);
				clearInterval(this.progressBarInterval);
				this.resetValue();
			}
		},
		async onPredictImage() {
			this.titleProgressBar = 'Co-mode AI が提案を作成中';
			this.intervalProgressBar();
			this.sendSocketEvent(1);
			this.$emit('update-status', (this.status = true), this.inputPrompt);
			try {
				let translatedText = await TranslateService.get(this.inputPrompt);
				let imgResult = await OpenAIService.generateImg(translatedText.data.text, 4);
				if (this.isCreator || !this.isControlScroll) {
					this.processValue = 100;
					imgResult.data.data.map((img) => {
						let resultImg = 'data:image/png;base64, ' + img.b64_json;
						this.imageList.push(resultImg);
					});
					this.sendSocketEvent(2);
					setTimeout(() => {
						this.predictionStatus = this.PREDICTION_STATUS.finished;
					}, 1000);
				}
				this.$emit('update-status', (this.status = false), this.inputPrompt);
			} catch (error) {
				if (!error.response) {
					this.errorModalContent = ComodeAiConst.NO_INTERNET;
				} else {
					let errorData = error.response.data.error;
					if (errorData.code == 'billing_hard_limit_reached') {
						this.errorModalContent = ComodeAiConst.BILLING_HARD_LIMIT_REACHED;
					} else if (errorData.message.includes('text that is not allowed')) {
						this.errorModalContent = ComodeAiConst.NSFW_TEXT;
					} else {
						this.errorModalContent = ComodeAiConst.GENERAL_ERROR;
					}
				}
				this.$bvModal.show(this.errorModalId);
				clearInterval(this.progressBarInterval);
				this.resetValue();
			}
		},

		intervalProgressBar(processValue = 0) {
			this.processValue = processValue;
			this.predictionStatus = this.PREDICTION_STATUS.processing;
			this.imageList = [];
			this.isImageSaved = new Array(4).fill(false);
			this.isShowBackdrop = new Array(4).fill(false);
			this.progressBarInterval = setInterval(() => {
				if (this.processValue <= 25) {
					this.processValue += 3;
				} else if (this.processValue > 25 && this.processValue <= 50) {
					this.processValue += 1;
				} else if (this.processValue > 50 && this.processValue <= 70) {
					this.processValue += 2;
				} else if (this.processValue > 70) {
					clearInterval(this.progressBarInterval);
				}
			}, 500);
		},
		onReceivedImageResult(imgList) {
			this.processValue = 100;
			this.imageList = imgList;
			setTimeout(() => {
				this.predictionStatus = this.PREDICTION_STATUS.finished;
			}, 1000);
		},
		onPreviewImage(idx) {
			this.selectedIdx = idx;
			this.image.url = this.imageList[idx];
			this.$bvModal.show(this.imgPreviewModalId);
		},
		async onSaveImage(imageInfo) {
			let date = new Date().getTime();
			let fileKey = await this._uploadImage({
				content: imageInfo.url,
				file_name: `image_${date}.png`,
				project_id: this.projectId,
				role: 'decision',
			});
			imageInfo.key = fileKey;
			this.sendSocketEvent(3);
			this.$emit('on-save', imageInfo);
			this.isImageSaved.splice(this.selectedIdx, 1, true);
			try {
				this.$refs.saveImageModal.clearComment();
			} catch (error) {
				console.log(error);
			}
		},
		onPreviewMobileImage(idx) {
			if (this.isImageSaved[idx]) {
				this.isShowBackdrop.splice(idx, 1, true);
			} else {
				this.image.url = this.imageList[idx];
				this.selectedIdx = idx;
				this.$bvModal.show(this.mobilePreviewModalId);
			}
		},
		/**
		 * Upload image
		 * @params {Object} file content
		 * @returns uploaded file key
		 */
		async _uploadImage(params) {
			const CancelToken = axios.CancelToken;
			this.source = CancelToken.source();
			let config = {
				onUploadProgress: (progressEvent) => {
					let { loaded, total } = progressEvent;
					let percent =
						PROGRESS_READER_LIMIT + Math.floor((loaded * PROGRESS_POST_LIMIT) / total);

					this.progressValue = percent;
				},
				cancelToken: this.source.token,
			};

			try {
				let response = await TransferService.post(params, config);
				if (!response || response.status !== 200) {
					throw 'Upload Image failed!';
				}

				console.log('%c Upload image successfully!', 'color: red');
				return response.data.key;
			} catch (error) {
				console.log(error);
			}
		},
		sendRealTimeData() {
			if (this.predictionStatus == this.PREDICTION_STATUS.processing) {
				this.sendSocketEvent(1);
			} else if (this.predictionStatus == this.PREDICTION_STATUS.finished) {
				this.sendSocketEvent(2);
			}
			this.sendSocketEvent(0);
		},
		resetValue() {
			this.imageList = [];
			this.isImageSaved = new Array(4).fill(false);
			this.isShowBackdrop = new Array(4).fill(false);
			this.predictionStatus = this.PREDICTION_STATUS.init;
			this.processValue = 0;
		},
		sendSocketEvent(type) {
			// if (!this.isCreator || !this.isControlScroll) {
			// 	return;
			// }
			if (type == 0) {
				socketClient.send('data_transfer', {
					data: {
						event_name: 'send_prompt_to_client',
						prompt: this.inputPrompt,
					},
					room: this.projectId,
				});
			} else if (type == 1) {
				socketClient.send('data_transfer', {
					data: {
						event_name: 'on_predict_image',
						processValue: this.processValue,
					},
					room: this.projectId,
				});
			} else if (type == 2) {
				socketClient.send('data_transfer', {
					data: {
						event_name: 'send_image_result_to_client',
						imgList: this.imageList,
					},
					room: this.projectId,
				});
			} else if (type == 3) {
				socketClient.send('data_transfer', {
					data: {
						event_name: 'image_saved',
						selectedIdx: this.selectedIdx,
					},
					room: this.projectId,
				});
			} else if (type == 4) {
				socketClient.send('data_transfer', {
					data: {
						event_name: 'error',
					},
					room: this.projectId,
				});
			}
		},
	},
	mounted() {
		setTimeout(() => {
			// if (!this.isCreator) {
			socketClient.listen('new_data_transfer', async (data) => {
				switch (data.event_name) {
					case `send_prompt_to_client`:
						this.inputPrompt = data.prompt;
						break;
					case `on_predict_image`:
						this.intervalProgressBar(data.processValue);
						break;
					case `send_image_result_to_client`:
						this.onReceivedImageResult(data.imgList);
						break;
					case `image_saved`:
						this.isImageSaved.splice(data.selectedIdx, 1, true);
						break;
					case `error`:
						this.$bvModal.show(this.errorModalId);
						clearInterval(this.progressBarInterval);
						this.resetValue();
						break;
					default:
						break;
				}
			});
			// }
		}, 1000);
	},
};
