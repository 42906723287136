import ApiService from './api.service';

const serviceURL = 'decision_detail';
const DecisionDetailService = {
	/**
	 * Create new Decision record
	 * @param {Object} body {doc_content: {decision Object}}
	 * @returns {Object} include success message and record ID
	 */
	post(body) {
		return ApiService.post(`${serviceURL}/`, body);
	},

	/**
	 * Update Decision record
	 * @param {Integer} decisionDetailId Project ID
	 * @param {Object} body {doc_content: {decision Object}}
	 * @returns {Object} success message
	 */
	update(decisionDetailId, body) {
		return ApiService.put(`${serviceURL}/${decisionDetailId}`, body);
	},

	/**
	 * Delete DecisionDetail
	 * @param {Integer} id decision_detail ID
	 * @returns Success message
	 */
	delete(id) {
		return ApiService.delete(`${serviceURL}/${id}`);
	},
	/**
	 * search DecisionDetail
	 * @param {Object} query params
	 * @returns {Array} records
	 */
	search(query) {
		console.log({ query });
		return ApiService.query(`${serviceURL}/search`, {
			params: {
				...query,
			},
		});
	},
};

export default DecisionDetailService;
